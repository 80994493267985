import * as React from "react";

export const Footer: React.FC = () => {
  let currentYear = new Date().getFullYear();

  return (
    <footer className="flex w-full max-w-2xl mx-auto m-6 flex-1 bg-gray-100 flex-col items-center justify-center px-2 sm:px-8 z-50 text-center text-sm">
      &copy; {currentYear} 1Password. All rights reserved.{" "}
    </footer>
  );
};

export default Footer;
